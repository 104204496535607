import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialissue',
  templateUrl: './specialissue.component.html',
  styleUrls: ['./specialissue.component.css']
})
export class SpecialissueComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aimscope',
  templateUrl: './aimscope.component.html',
  styleUrls: ['./aimscope.component.css']
})
export class AimscopeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

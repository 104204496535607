import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ethics',
  templateUrl: './ethics.component.html',
  styleUrls: ['./ethics.component.css']
})
export class EthicsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

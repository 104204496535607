import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-editorialboard',
  templateUrl: './editorialboard.component.html',
  styleUrls: ['./editorialboard.component.css']
})
export class EditorialboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
